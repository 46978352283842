import dayjs from "dayjs";

const pickupStatuses = [
  "placed",
  "confirmed",
  "ready",
  "rejected",
  "canceled",
  "fulfilled",
];

const shippingStatuses = [
  "placed",
  "confirmed",
  "ready",
  "shipped",
  "out_for_delivery",
  "rejected",
  "canceled",
  "fulfilled",
];

const dineinStatuses = [
  "placed",
  "confirmed",
  "ready",
  "rejected",
  "canceled",
  "fulfilled",
];

/* -------------------------------
  Pickup
---------------------------------*/

const getEntriesForPickupPlaced = (orderDate) => {
  return [
    { status: "success", value: "Order placed - " + orderDate },
    { status: "active", value: "Waiting for confirmation" },
    { status: "pending", value: "Next: Processing" },
    { status: "pending", value: "Next: Pickup" },
  ];
};

const getEntriesForPickupConfirmed = (orderDate) => {
  return [
    { status: "success", value: "Order placed - " + orderDate },
    { status: "success", value: "Your order is confirmed" },
    { status: "active", value: "Order is being processed" },
    { status: "pending", value: "Next: Pickup" },
  ];
};

const getEntriesForPickupReady = (orderDate) => {
  return [
    { status: "success", value: "Order placed - " + orderDate },
    { status: "success", value: "Your order is confirmed" },
    { status: "success", value: "Order is ready" },
    { status: "active", value: "Please pickup your order from store" },
  ];
};

const getEntriesForPickupFulfilled = (orderDate) => {
  return [
    { status: "success", value: "Order placed - " + orderDate },
    { status: "success", value: "Your order is confirmed" },
    { status: "success", value: "Order is ready" },
    { status: "success", value: "Pickup complete." },
  ];
};

const getEntriesForPickupRejected = (orderDate, previousStatus) => {
  const previousEntries = getEntriesForPickupStatus(orderDate, previousStatus);
  const completedEntries = previousEntries.filter(
    (x) => x.status === "success"
  );
  completedEntries.push({
    status: "canceled",
    value: "Your order was canceled as it could not be fulfilled.",
    subtext:
      "Reach out via Whatsapp or email for any queries using the help link above",
  });
  return completedEntries;
};

const getEntriesForPickupCanceled = (orderDate, previousStatus) => {
  const previousEntries = getEntriesForPickupStatus(orderDate, previousStatus);
  const completedEntries = previousEntries.filter(
    (x) => x.status === "success"
  );
  completedEntries.push({
    status: "canceled",
    value: "Your order was canceled.",
    subtext:
      "Reach out via Whatsapp or email for any queries using the help link above",
  });
  return completedEntries;
};

const getEntriesForPickupStatus = (orderDate, status, previousStatus) => {
  if (status === "placed") {
    return getEntriesForPickupPlaced(orderDate);
  }
  if (status === "confirmed") {
    return getEntriesForPickupConfirmed(orderDate);
  }
  if (status === "ready") {
    return getEntriesForPickupReady(orderDate);
  }
  if (status === "fulfilled") {
    return getEntriesForPickupFulfilled(orderDate);
  }
  if (status === "rejected") {
    return getEntriesForPickupRejected(orderDate, previousStatus);
  }

  if (status === "canceled") {
    return getEntriesForPickupCanceled(orderDate, previousStatus);
  }
};

const getEntriesForPickup = (order) => {
  const orderDate = dayjs(new Date(order.cAt._seconds * 1000)).format("lll");
  const status = order.status || "placed";
  const previousStatus = order.previousStatus || "placed";
  return getEntriesForPickupStatus(orderDate, status, previousStatus);
};

/* -------------------------------
  Shipping
-------------------------------- */

const getEntriesForShipPlaced = (orderDate) => {
  return [
    { status: "success", value: "Order placed - " + orderDate },
    { status: "active", value: "Waiting for confirmation" },
    { status: "pending", value: "Shipped" },
    { status: "pending", value: "Delivered" },
  ];
};

const getEntriesForShipConfirmed = (orderDate) => {
  return [
    { status: "success", value: "Order placed - " + orderDate },
    { status: "success", value: "Your order is confirmed." },
    { status: "active", value: "Processing your order" },
    { status: "pending", value: "Waiting for shipping" },
    { status: "pending", value: "Delivery" },
  ];
};

const getEntriesForShipReady = (orderDate) => {
  return [
    { status: "success", value: "Order placed - " + orderDate },
    { status: "success", value: "Your order is confirmed." },
    { status: "success", value: "Order is ready for shipping" },
    { status: "pending", value: "Waiting for shipping" },
    { status: "pending", value: "Delivery" },
  ];
};

const getEntriesForShipped = (orderDate) => {
  return [
    { status: "success", value: "Order placed - " + orderDate },
    { status: "success", value: "Your order is confirmed." },
    { status: "success", value: "Order is ready for shipping" },
    { status: "success", value: "Shipped" },
    { status: "active", value: "Waiting for delivery" },
  ];
};

const getEntriesForOutForDelivery = (orderDate) => {
  return [
    { status: "success", value: "Order placed - " + orderDate },
    { status: "success", value: "Your order is confirmed." },
    { status: "success", value: "Order is ready for shipping" },
    { status: "success", value: "Shipped" },
    { status: "active", value: "Out for delivery" },
  ];
};

const getEntriesForShipFulfilled = (orderDate) => {
  return [
    { status: "success", value: "Order placed - " + orderDate },
    { status: "success", value: "Your order is confirmed." },
    { status: "success", value: "Order is ready for shipping" },
    { status: "success", value: "Shipped" },
    { status: "success", value: "Your order was delivered." },
  ];
};

const getEntriesForShipRejected = (orderDate, previousStatus) => {
  const previousEntries = getEntriesForShippingOrderByStatus(
    orderDate,
    previousStatus
  );
  const completedEntries = previousEntries.filter(
    (x) => x.status === "success"
  );
  completedEntries.push({
    status: "canceled",
    value: "Your order was canceled as it could not be fulfilled.",
    subtext:
      "Reach out via Whatsapp or email for any queries using the help link above",
  });
  return completedEntries;
};

const getEntriesForShipCanceled = (orderDate, previousStatus) => {
  const previousEntries = getEntriesForShippingOrderByStatus(
    orderDate,
    previousStatus
  );
  const completedEntries = previousEntries.filter(
    (x) => x.status === "success"
  );
  completedEntries.push({
    status: "canceled",
    value: "Your order is cancelled.",
    subtext:
      "Reach out via Whatsapp or email for any queries using the help link above",
  });
  return completedEntries;
};

const getEntriesForShippingOrderByStatus = (
  orderDate,
  status,
  previousStatus
) => {
  if (status === "placed") {
    return getEntriesForShipPlaced(orderDate);
  }
  if (status === "confirmed") {
    return getEntriesForShipConfirmed(orderDate);
  }
  if (status === "ready") {
    return getEntriesForShipReady(orderDate);
  }
  if (status === "shipped") {
    return getEntriesForShipped(orderDate);
  }
  if (status === "out_for_delivery") {
    return getEntriesForOutForDelivery(orderDate);
  }
  if (status === "fulfilled") {
    return getEntriesForShipFulfilled(orderDate);
  }
  if (status === "rejected") {
    return getEntriesForShipRejected(orderDate, previousStatus);
  }

  if (status === "canceled") {
    return getEntriesForShipCanceled(orderDate, previousStatus);
  }
};

const getEntriesForShipping = (order) => {
  const orderDate = dayjs(new Date(order.cAt._seconds * 1000)).format("lll");
  const status = order.status || "placed";
  const previousStatus = order.previousStatus || "placed";
  return getEntriesForShippingOrderByStatus(orderDate, status, previousStatus);
};

const getStatusEntries = (order) => {
  if (order.deliveryMode === "pickup") {
    return getEntriesForPickup(order);
  } else if (order.deliveryMode === "ship") {
    return getEntriesForShipping(order);
  }
  return [];
};

export { getStatusEntries };
