<template>
  <div>
    <div v-for="product in products" :key="product.id">
      <div class="product-entry">
        <div class="product-image">
          <SfImage :src="product.image" :alt="product.title" />
        </div>
        <div class="product-details">
          <div>
            {{ product.title }}
            <span v-if="product.variantName">
              ( {{ product.variantName }} )
            </span>
          </div>
          <div class="product-values">
            <div class="qty">Qty: {{ product.qty }}</div>
            <div>{{ product.productPrice | price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SfImage } from "@lib/components";

export default {
  components: {
    SfImage,
  },
  props: {
    products: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.product-image {
  height: 60px;
  width: 60px;
  padding-right: 0.75rem;
}

.product-values {
  display: flex;
  justify-content: space-between;
  font-weight: lighter;
}

.product-entry {
  display: flex;
  justify-content: flex-start;
  margin: 0.5rem 0;

  .product-image {
    width: 60px;
    height: 60px;
    padding-right: 1rem;
  }

  .product-details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
  }

  .product-values {
    display: flex;
    justify-content: space-between;
    font-weight: lighter;
    font-size: var(--font-sm);
  }
}
</style>
