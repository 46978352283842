<template>
  <div class="order-status-tracker">
    <div>
      <div
        class="status-entry"
        v-for="(entry, index) in statusTrack"
        :key="index"
      >
        <div class="status-indicator">
          <div :class="['status-icon', entry.status]">
            <font-awesome-icon
              v-if="entry.status === 'success'"
              :icon="['fas', 'check']"
              size="lg"
              style="margin-top: 5px"
            />
            <font-awesome-icon
              v-if="entry.status === 'active'"
              :icon="['fas', 'circle']"
              size="sm"
              style="margin-top: 8px; margin-left: 8px"
            />
            <font-awesome-icon
              v-if="entry.status === 'canceled'"
              :icon="['fas', 'minus']"
              size="lg"
              style="margin-top: 5px; margin-left: 5px"
            />
          </div>
        </div>
        <div class="status-text">{{ entry.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statusTrack: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-color-default: rgba(0, 0, 0, 0.25);
$icon-color-success: #60c878;
$icon-color-active: #efd173;

.order-status-tracker {
  padding: 0 0 1rem 0;

  .status-entry {
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 0;

    .status-indicator {
      padding-right: var(--spacer-sm);
    }

    .status-icon {
      display: block;
      margin: 0 auto;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      border: 5px solid $icon-color-default;
      color: $icon-color-default;

      svg {
        margin-top: 4px;
        margin-left: 4px;
      }
    }

    .status-icon.success {
      border: 5px solid $icon-color-success;
      color: $icon-color-success;
    }

    .status-icon.active {
      border: 5px solid $icon-color-active;
      color: $icon-color-active;
    }

    .status-icon.pending {
      $icon-color: rgba(0, 0, 0, 0.4);
    }
  }

  .status-text {
    line-height: 40px;
    vertical-align: middle;
  }

  .status-icon {
    width: 40px;
    margin-right: 16px;
  }
}
</style>
