<template>
  <div class="order-details">
    <SfLoader v-if="loading" :loading="loading" />
    <div v-else>
      <div class="top-bar mobile-only">
        <div class="top-bar__icon">
          <SfIcon
            icon="chevron_left"
            size="14px"
            role="button"
            aria-label="back"
            @click="$emit('on-back')"
          />
        </div>
        <div class="top-bar__title">
          <div>{{ $t("ORDER #") + order.bill.toUpperCase() }}</div>
          <SfButton @click="onHelp" class="sf-button--pure text-primary"
            >HELP</SfButton
          >
        </div>
      </div>
      <div class="order-header desktop-only">
        <div class="order-header__icon">
          <SfIcon
            icon="chevron_left"
            size="14px"
            role="button"
            aria-label="back"
            @click="$emit('on-back')"
          />
        </div>
        <div class="top-bar__title">
          <div>{{ $t("ORDER #") + order.bill.toUpperCase() }}</div>
          <SfButton @click="onHelp" class="sf-button--pure text-primary"
            >HELP</SfButton
          >
        </div>
      </div>
      <div class="order-details__content">
        <div class="order-details__tracker">
          <StatusTracker :status-track="statusTrack" />
        </div>
        <div v-if="order.cancellationRequested">
          Cancellation is requested on this order
        </div>
        <div class="full-width gutter section-title">Order Details</div>
        <div class="order-details__products">
          <OrderProducts :products="products" />
        </div>
        <div class="order-details__summary">
          <div>
            <SfProperty
              v-for="total in order.order.totals"
              :key="total.code"
              :name="total.code | capitalize"
              :value="total.amount | price"
              class="sf-property--full-width property"
            >
            </SfProperty>
          </div>
        </div>
        <div class="full-width gutter"></div>
        <div class="order-details__informations">
          <div v-if="address">
            <SfHeading
              :title="$t('Shipping address')"
              :level="4"
              class="sf-heading--left sf-heading--no-underline"
            />
            <address>
              <p>{{ personalDetails.fullName }}</p>
              <p>
                {{ address.streetName }} {{ address.apartment }}<br />
                {{ address.city }}, {{ address.state }},
                {{ address.zipCode || address.postcode }}
              </p>
              <p>{{ address.country }}</p>
            </address>
          </div>
          <div v-if="order.shipping_description">
            <SfHeading
              :title="$t('Shipping method')"
              :level="4"
              class="sf-heading--left sf-heading--no-underline"
            />
            <p>{{ order.shipping_description }}</p>
          </div>
          <div>
            <SfHeading
              :title="$t('Payment Mode')"
              :level="4"
              class="sf-heading--left sf-heading--no-underline"
            />
            <p>{{ paymentMethod }}</p>

            <SfHeading
              :title="$t('Payment Status')"
              :level="4"
              class="sf-heading--left sf-heading--no-underline"
            />
            <p>
              {{ paymentStatus }}
            </p>
          </div>
        </div>
        <div class="full-width gutter section-title">Your Details</div>
        <div class="order-details__additional">
          <div class="personal-details">
            <div class="first-row">
              <div class="name">{{ personalDetails.fullName }}</div>
            </div>
            <div class="second-row">
              <div v-if="personalDetails.phone" class="phoneNumber">
                {{ personalDetails.phone }}
              </div>
              <div
                class="dot"
                v-if="personalDetails.phone && personalDetails.email"
              >
                &bull;
              </div>
              <div v-if="personalDetails.email" class="email">
                {{ personalDetails.email }}
              </div>
            </div>
          </div>
        </div>
        <div class="full-width gutter"></div>
        <div class="order-details__actions">
          <SfButton
            v-if="false && !isOrderCompleted"
            class="sf-button--outline color-secondary order-action"
            @click="onCancelRequest"
          >
            Request Cancellation
          </SfButton>
          <SfButton
            class="sf-button--outline color-secondary order-action"
            @click="$emit('on-back')"
          >
            Back
          </SfButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SfHeading,
  SfProperty,
  SfImage,
  SfIcon,
  SfLoader,
  SfButton,
} from "@lib/components";
import { mapActions, mapGetters } from "vuex";
import { getStatusEntries } from "@lib/utility/statusTrack";
import OrderProducts from "@theme/components/order/molecules/order-products";
import StatusTracker from "@theme/components/order/molecules/status-tracker";
import { ModalList } from "@theme/store/ui/modals";

const getProductPrice = (product) => {
  return product.finalPrice || product.discountedPrice || product.basePrice;
};

const getProductTotalPrice = (product) => {
  return getProductPrice(product) * product.qty;
};

export default {
  name: "ViewOrder",
  components: {
    SfHeading,
    SfProperty,
    SfButton,
    SfIcon,
    SfLoader,
    OrderProducts,
    StatusTracker,
  },

  props: {
    orderId: {
      type: String,
      default: null,
    },
    orderObj: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      orders: "user/getOrdersHistory",
    }),
    order() {
      if (this.orderId === null) return this.orderObj;
      if (!this.orders.length) return null;
      return this.orders.find((x) => x.id === this.orderId);
    },
    products() {
      if (!this.order) return;
      return this.order.order.cart.map((x) => ({
        ...x,
        productPrice: getProductPrice(x),
        totalPrice: getProductTotalPrice(x),
      }));
    },
    loading() {
      return !this.order;
    },
    paymentMethod() {
      return (this.order && this.order.paymentMode) || "None";
    },
    paymentStatus() {
      if (this.order.paymentStatus === "verify") {
        return "Pending";
      }
      if (
        this.paymentMethod === "no_payment" ||
        this.paymentMethod === "None"
      ) {
        return "-";
      }
      return (
        this.order.paymentStatus || (this.order.paymentMode ? "Received" : "-")
      );
    },
    billingAddress() {
      return this.order && this.order.billing_address;
    },
    address() {
      return this.order && this.order.order.shippingDetails;
    },
    personalDetails() {
      return this.order && this.order.order.personalDetails;
    },
    status() {
      return this.order.status || "placed";
    },
    isOrderCompleted() {
      return ["fulfilled", "canceled", "rejected"].indexOf(this.status) !== -1;
    },
    statusTrack() {
      return getStatusEntries(this.order);
    },
  },
  methods: {
    ...mapActions("ui", {
      openModal: "openModal",
    }),
    onCancelRequest() {
      this.openModal({
        name: ModalList.OrderCancelRequest,
        payload: { orderId: this.order.id, bill: this.order.bill },
      });
    },
    onHelp() {
      this.openModal({
        name: ModalList.OrderHelp,
        payload: { order: this.order },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "~@lib/styles/helpers" as *;

.gutter {
  background: rgba(0, 0, 0, 0.05);
  height: 0.33rem;
}

.gutter.section-title {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  height: auto;
  color: rgba(0, 0, 0, 0.55);
  font-size: var(--font-sm);
  text-transform: uppercase;
}

.section-title {
  margin-bottom: 1rem;
}

.top-bar {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: var(--bar-height, 3.125rem);
  flex: 0 0 var(--bar-height, 3.125rem);
  padding: var(--bar-padding, 0 var(--spacer-sm));
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  @include font(
    --bar-font,
    var(--font-medium),
    var(--font-base),
    1.6,
    var(--font-family-primary)
  );
  &__icon {
    --icon-width: 0.875rem;
    margin-right: var(--spacer-sm);
  }

  &__title {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}

.order-header {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: var(--bar-height, 3.125rem);
  flex: 0 0 var(--bar-height, 3.125rem);
  padding: var(--bar-padding, 0 var(--spacer-sm));
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  @include font(
    --bar-font,
    var(--font-medium),
    var(--font-base),
    1.6,
    var(--font-family-primary)
  );
  &__icon {
    --icon-width: 0.875rem;
    margin-right: var(--spacer-sm);
  }

  &__title {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}

.order-details {
  font-family: var(--font-family-primary);

  &__content {
    padding: var(--spacer-sm);

    .full-width {
      margin-left: calc(-1 * var(--spacer-sm));
      margin-right: calc(-1 * var(--spacer-sm));
      padding-left: var(--spacer-sm);
      padding-right: var(--spacer-sm);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 0;
  }
  &__back {
    margin-right: var(--spacer-lg);
  }
  &__status {
    margin-left: var(--spacer-lg);
  }
  &__products {
    margin-top: var(--spacer-lg);
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    img {
      display: block;
    }
    .sf-table__header--center {
      text-align: center;
    }

    .sf-image__wrapper {
      .sf-image,
      .image-placeholder {
        max-height: 60px;
        max-width: 60px;
      }

      .placeholder-text {
        font-size: var(--font-sm);
      }
    }
  }
  &__summary {
    margin-top: var(--spacer-base);
    display: flex;
    justify-content: flex-end;
  }
  &__informations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--spacer-base);
    @include for-desktop {
      margin-top: var(--spacer-xl);
      flex-direction: row;
      justify-content: space-between;
    }
    .sf-heading {
      margin-top: var(--spacer-base);
    }
    p {
      margin: var(--spacer) 0 0 0;
    }

    address {
      font-style: normal;
    }
  }
  &__additional {
    margin-top: var(--spacer-base);
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: var(--spacer-base);
    margin-bottom: var(--spacer-sm);
  }

  .property {
    margin: 0 0 var(--spacer-base) 0;
    @include for-desktop {
      margin: 0 0 var(--spacer-sm) 0;
      &__total {
        padding: var(--spacer-base) 0 0 0;
      }
    }
  }
  .property-total {
    --property-name-font-weight: 500;
    --property-value-font-weight: 500;
  }
  .order-details__summary {
    .sf-property__name {
      min-width: 100px;
    }
    .sf-property__value {
      min-width: 180px;
      text-align: center;
    }
  }
  .table {
    &__header {
      text-align: center;
      &:last-child {
        text-align: right;
      }
    }
    &__image {
      --image-width: 5.125rem;
      text-align: left;
    }
    &__price {
      text-align: right;
    }
    @include for-mobile {
      &__header,
      &__image,
      &__price {
        text-align: left;
      }
      &__header {
        &:last-child {
          text-align: left;
        }
      }
    }
  }

  .order-action {
    padding: 0.66rem 1.5rem;
    --button-font-size: var(--font-xs);
    border-radius: 0.375rem;

    &.color-secondary {
      --button-color: var(--c-black-lighten);
      --button-border-color: var(--c-black-lighten);
    }
  }

  .personal-details {
    margin-top: 1.5rem;
    font-size: var(--font-base);
    font-family: var(--font-family-primary);
    font-weight: 300;

    .first-row {
      display: flex;
      justify-content: space-between;
    }

    .second-row {
      display: flex;
      justify-content: left;
      margin-top: 0.66rem;
      margin-bottom: 1.33rem;

      .dot {
        margin: 0 0.75rem;
      }
    }
  }
}
</style>
